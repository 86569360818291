import * as d3 from 'd3';
import { rgba } from 'polished';
import { FC } from 'react';
import { useSystemTheme } from '../../layouts/utils/useSystemTheme';
import { Flex, Typography } from '../../ui';

interface HeartRateChartProps {
  heartRateHistory: number[];
  heartRateExtrapolation?: number;
}

export const HeartRateChart: FC<HeartRateChartProps> = ({
  heartRateHistory: heartRateHistoryWithoutExtrapolation,
  heartRateExtrapolation,
}) => {
  const theme = useSystemTheme();

  const heartRateHistory = heartRateExtrapolation
    ? [...heartRateHistoryWithoutExtrapolation, heartRateExtrapolation]
    : heartRateHistoryWithoutExtrapolation;

  const maxHeartRate = Math.max(...heartRateHistory);
  const minHeartRate = Math.min(...heartRateHistory);

  const topMarginPercentage = 0.2;
  const bottomMarginPercentage = 0.6;

  const chartDisplayMax = maxHeartRate + maxHeartRate * topMarginPercentage;
  const chartDisplayMin = minHeartRate - minHeartRate * bottomMarginPercentage;

  const chartWidth = 120;
  const chartHeight = 32;

  // Range between 1, 2 & 3 depending on the number of heart rate history items
  const barWidth =
    heartRateHistory.length > 30 ? 1.5 : heartRateHistory.length > 20 ? 2 : 3;

  // Calculating the scale for y-axis
  const scaleY = d3
    .scaleLinear()
    .domain([chartDisplayMin, chartDisplayMax])
    .range([chartHeight, 0])
    .clamp(true);

  // Calculating the scale for x-axis
  const scaleX = d3
    .scaleLinear()
    .domain([-1, heartRateHistory.length + 1])
    .range([0, chartWidth]);

  return (
    <Flex gap={4}>
      <Flex flexDirection="column" alignItems="flex-end">
        <Typography.Caption size="S" opacity={0.5}>
          ↑ {Math.floor(maxHeartRate)} BPM
        </Typography.Caption>
        <Typography.Caption size="S" opacity={0.5}>
          ↓ {Math.floor(minHeartRate)} BPM
        </Typography.Caption>
      </Flex>

      <svg
        width={chartWidth}
        height={chartHeight}
        viewBox={`0 0 ${chartWidth} ${chartHeight}`}
      >
        {/* Line indicating the max value */}
        <line
          x1={0}
          y1={scaleY(maxHeartRate)}
          x2={chartWidth}
          y2={scaleY(maxHeartRate)}
          stroke={theme.foreground}
          strokeWidth="1"
          opacity={0.2}
          strokeDasharray="4"
        />
        {/* Line indicating the min value */}
        <line
          x1={0}
          y1={scaleY(minHeartRate)}
          x2={chartWidth}
          y2={scaleY(minHeartRate)}
          stroke={theme.foreground}
          strokeWidth="1"
          opacity={0.2}
          strokeDasharray="4"
        />
        {heartRateHistory.map((d, i) => (
          <rect
            key={i}
            x={scaleX(i)}
            y={scaleY(d)}
            width={barWidth}
            height={chartHeight - scaleY(d)}
            fill={
              i === heartRateHistory.length - 1 && heartRateExtrapolation
                ? `url(#foreground-gradient-${i})`
                : `url(#accent-gradient-${i})`
            }
            rx={barWidth / 2}
            ry={barWidth / 2}
          />
        ))}

        {heartRateHistory.map((d, i) => (
          <linearGradient
            key={i}
            id={`accent-gradient-${i}`}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop
              offset="0%"
              stopColor={rgba(
                theme.accent,
                1 - (maxHeartRate - d) / (maxHeartRate - chartDisplayMin)
              )}
            />
            <stop offset="100%" stopColor={rgba(theme.accent, 0)} />
          </linearGradient>
        ))}

        {heartRateHistory.map((d, i) => (
          <linearGradient
            key={i}
            id={`foreground-gradient-${i}`}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop offset="0%" stopColor={rgba(theme.foreground, 0.2)} />
            <stop offset="100%" stopColor={rgba(theme.foreground, 0)} />
          </linearGradient>
        ))}
      </svg>
    </Flex>
  );
};
