import React, { useEffect, useMemo, useState } from 'react';

import letters from '../assets/letters.json';
import { HeartRateChart } from '../components/HeartRateChart/HeartRateChart';
import { HeartRateIndicator } from '../components/HeartRateIndicator/HeartRateIndicator';
import { PlainLayout } from '../layouts/PlainLayout';
import { useSystemTheme } from '../layouts/utils/useSystemTheme';
import { ContextualPopup, Divider, Flex, Typography } from '../ui';
import SEO from '../components/seo';
import { ECGChart } from '../components/ECGChart/ECGChart';

const timePerLetter = 0.1;

const Index: React.FC = ({ ...props }) => {
  const theme = useSystemTheme();
  const [timeOnPage, setTimeOnPage] = useState(0);

  const [heartRate, setHeartRate] = useState(0);
  const [heartRateHistory, setHeartRateHistory] = useState<number[]>([]);
  const [lastSync, setLastSync] = useState(0);

  useEffect(() => {
    fetch(
      'https://knowing-bobcat-27719.upstash.io/get/health?_token=AmxHAAIgcDG0IZNuGuszTHlmAWrZWTFBoHnWg5RplQoL8CblZ6VjLw'
    )
      .then((res) => res.json())
      .then((data) => {
        const result = JSON.parse(data.result);
        const resultHeartRate = result.heartRate;
        const resultHeartRateHistory = result.heartRate1HourHistory;
        const resultLastSync = result.lastSync;

        setHeartRate(resultHeartRate);
        setHeartRateHistory(resultHeartRateHistory);
        setLastSync(resultLastSync);
        const maxHeartRate = Math.max(...resultHeartRateHistory);
        const minHeartRate = Math.min(...resultHeartRateHistory);

        // update heart rate every second with extrapolated randomized value (it should always stay within the min and max)
        // it should also only change by 1 or 2 BPM each time
        const interval = setInterval(() => {
          const oneOrTwo = Math.floor(Math.random() * 2) + 1;
          const direction = Math.random() < 0.5 ? -1 : 1;
          const newHeartRate =
            (heartRate || parseInt(resultHeartRate)) + oneOrTwo * direction;
          if (newHeartRate > maxHeartRate) {
            setHeartRate(maxHeartRate);
          } else if (newHeartRate < minHeartRate) {
            setHeartRate(minHeartRate);
          } else {
            setHeartRate(newHeartRate);
          }
        }, 5000);
        return () => clearInterval(interval);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeOnPage((prev) => prev + 1 / 20);
    }, 1000 / 20);

    return () => clearInterval(interval);
  }, []);

  const { letter } = useMemo(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const standardisedDate = `${year}-${month}-${day}`;
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const nonBreakingSpace = '\u00A0';
    const monthName = monthNames[today.getMonth()];
    const dateFormatted = `${monthName}${nonBreakingSpace}${day}`;

    const todaysLetter = (letters as any)[standardisedDate] || 'nothing';

    const containsLineBreak = todaysLetter.includes('\n');
    const optionalIndent = containsLineBreak ? '' : '\t';

    const letterWithDate = `${dateFormatted}.\n\n${optionalIndent}${todaysLetter}`;

    return { letter: letterWithDate };
  }, []);

  const charactersDisplayed = Math.min(
    Math.floor(timeOnPage / timePerLetter),
    letter.length
  );

  return (
    <PlainLayout {...props} /* background={<Scene />} */>
      <SEO titleOverride="Don't Ask" />

      <style>
        {`
          pre span {
            opacity: 0;
          }
          pre span.appear {
            animation: fadeIn 0.05s linear forwards;
          }

          @keyframes fadeIn {
            0% {
              opacity: 0;
              filter: blur(4px);
            }
            100% {
              opacity: 1;
              filter: blur(0px);
            }
          }
        `}
      </style>
      <div
        style={{
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
          flexDirection: 'column',
          maxWidth: 480,
          margin: 'auto',
          rowGap: 16,
          userSelect: 'none',
          lineHeight: 1.5,
          wordSpacing: '0.25em',
          padding: 24,
          boxSizing: 'border-box',
        }}
      >
        <pre
          style={{
            fontSize: '0.75rem',
            fontFamily: 'Special Elite, serif',
            wordWrap: 'break-word',
            whiteSpace: 'pre-wrap',
          }}
        >
          {letter.split('').map((char, index) => (
            <span
              key={index}
              className={index < charactersDisplayed ? 'appear' : ''}
            >
              {char}
            </span>
          ))}
        </pre>
      </div>

      {heartRate && (
        <div
          style={{
            position: 'absolute',
            right: 24,
            top: 16,
          }}
        >
          <ContextualPopup
            behaviour="click"
            handle={
              <Flex alignItems="center">
                <Typography.Caption size="L" opacity={0.5}>
                  Still alive—
                </Typography.Caption>

                <HeartRateIndicator heartRate={heartRate} size={16} />
              </Flex>
            }
          >
            {heartRateHistory && heartRate && (
              <>
                <ContextualPopup.Section>
                  <Flex justifyContent="space-between">
                    <Flex flexDirection="column">
                      <Typography.Caption size="L">
                        Heart Rate
                      </Typography.Caption>

                      <Flex gap={4} alignItems="center">
                        <HeartRateIndicator heartRate={heartRate} />

                        <Typography.Caption opacity={0.5}>
                          {Math.floor(heartRate)} BPM
                        </Typography.Caption>
                      </Flex>
                    </Flex>

                    <HeartRateChart
                      heartRateExtrapolation={heartRate}
                      heartRateHistory={heartRateHistory}
                    />
                  </Flex>
                </ContextualPopup.Section>
                <Divider.Horizontal />
              </>
            )}

            <ContextualPopup.Section>
              <Flex justifyContent="space-between">
                <Flex flexDirection="column">
                  <Typography.Caption size="L">
                    Electrocardiogram
                  </Typography.Caption>
                  <Typography.Caption opacity={0.5}>
                    No signs of atrial fibrilation
                  </Typography.Caption>
                </Flex>

                {/* Random ECG chart */}
                <ECGChart heartRate={heartRate} />
              </Flex>
            </ContextualPopup.Section>

            <Divider.Horizontal />

            <ContextualPopup.Section>
              <Flex justifyContent="space-between">
                <Typography.Caption size="L">
                  Last Synchronization
                </Typography.Caption>
                <Typography.Caption opacity={0.5} textAlign="left">
                  {new Date(lastSync).toLocaleString()}
                </Typography.Caption>
              </Flex>
            </ContextualPopup.Section>

            <Divider.Horizontal />

            <ContextualPopup.Section>
              <Typography.Caption opacity={0.5} textAlign="left">
                This is my (almost) real-time heart rate synchronised directly
                with my Apple Watch as frequently as the system allows. This
                data is extrapolated from recent historical data to provide a
                continuous heartbeat prediction.
                <br />
                <br />
                Fun fact; the beating heart animation is also synchronised with
                my real heart rate.
              </Typography.Caption>
            </ContextualPopup.Section>
          </ContextualPopup>
        </div>
      )}
    </PlainLayout>
  );
};

export default Index;
