import { FC, useEffect, useRef } from 'react';
import { useSystemTheme } from '../../layouts/utils/useSystemTheme';
import { rgba } from 'polished';

interface ECGChartProps {
  heartRate: number;
}

const getHeartBeatRhythm = (timestamp: number, bpm: number) => {
  const timeInSeconds = timestamp / 1000; // Convert timestamp from milliseconds to seconds
  const beatInterval = 60 / bpm; // Time between heart beats in seconds

  // Time within the current beat interval
  const timeInBeatCycle = timeInSeconds % beatInterval;

  let value;

  // P wave: A small bump before the QRS complex, going slightly above and below 0.5
  if (timeInBeatCycle < 0.1 * beatInterval) {
    value =
      0.5 + 0.1 * Math.sin((Math.PI / (0.1 * beatInterval)) * timeInBeatCycle);
  }
  // QRS complex: Sharp spike with values going both above and below 0.5
  else if (timeInBeatCycle < 0.15 * beatInterval) {
    let qrsProgress =
      (timeInBeatCycle - 0.1 * beatInterval) / (0.05 * beatInterval);
    if (qrsProgress < 0.5) {
      // Drop for the Q part (below 0.5)
      value = 0.5 - qrsProgress * 1.0;
    } else {
      // Spike for the R part (above 0.5), then back down for the S part
      value = 0.5 + (1 - Math.abs(2 * qrsProgress - 1)) * 1.5;
    }
  }
  // T wave: A smaller bump after the QRS complex, oscillating around 0.5
  else if (timeInBeatCycle < 0.25 * beatInterval) {
    let tProgress =
      (timeInBeatCycle - 0.15 * beatInterval) / (0.1 * beatInterval);
    value = 0.5 + 0.15 * Math.sin(Math.PI * tProgress);
  }
  // Rest of the cycle: Flat line (neutral value 0.5)
  else {
    value = 0.5;
  }

  return value;
};

/**
 * One point is a number from 0 to 1 indicating the height of the line at that point
 * stretched across the height of the canvas
 */
type Point = number;
export const ECGChart: FC<ECGChartProps> = ({ heartRate }) => {
  const theme = useSystemTheme();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const width = 120;
  const height = 32;
  const pointSize = 2;

  const points = useRef<Point[]>([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Adjust canvas dimensions for retina display
    const devicePixelRatio = window.devicePixelRatio || 1;
    canvas.width = width * devicePixelRatio;
    canvas.height = height * devicePixelRatio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    ctx.scale(devicePixelRatio, devicePixelRatio); // Scale the context to match the adjusted dimensions

    const generatePoint = () => {
      const magnitude = Math.random() * 0.1;
      const frequency = 1000;
      const noise =
        (Math.sin(new Date().getTime() / frequency) + 1) * magnitude * 0.5; // Reduced noise magnitude for smoother output
      const point = getHeartBeatRhythm(Date.now(), heartRate) + noise;
      return point;
    };

    const addPointAndDraw = () => {
      if (points.current.length < width) {
        points.current.push(generatePoint());
      } else {
        points.current.shift();
        points.current.push(generatePoint());
      }
      draw();
    };

    const draw = () => {
      ctx.clearRect(0, 0, width, height);

      // Draw the ECG line
      ctx.beginPath();
      ctx.strokeStyle = theme.accent;
      ctx.lineCap = 'round';
      for (let i = points.current.length - 1; i >= 0; i--) {
        // Draw from the right (width) to the left
        const reverseIndex = points.current.length - 1 - i;
        ctx.lineTo(
          width - reverseIndex - pointSize,
          (points.current[i] * height) / 2
        );
      }
      ctx.stroke();

      // Draw a circle point on the right, indicating the current value
      ctx.beginPath();
      ctx.arc(
        width - pointSize,
        (points.current[points.current.length - 1] * height) / 2,
        pointSize,
        0,
        2 * Math.PI
      );
      ctx.fillStyle = theme.accent;
      ctx.fill();
    };

    // add point and redraw every 100ms
    const interval = setInterval(() => {
      addPointAndDraw();
    }, 1000 / 120);

    return () => clearInterval(interval);
  }, []);

  const linearGradient = `linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 1) 100%)`;
  const heightMinusOne = height - 1;
  const backgroundGuideAlpha = 0.1;
  const horizontalLinesCount = 12;

  return (
    <div style={{ position: 'relative', width: width, height: height }}>
      <svg
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          maskImage: `radial-gradient(ellipse at 100% 20%, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 65%)`,
          maskSize: 'cover',
        }}
      >
        {/** 5 horizontal lines across the height of the canvas using for loop */}
        {Array.from({ length: horizontalLinesCount }).map((_, index) => (
          <line
            key={index}
            x1={0}
            y1={(index * heightMinusOne) / (horizontalLinesCount - 1) + 0.5}
            x2={width - 0.5 - pointSize}
            y2={(index * heightMinusOne) / (horizontalLinesCount - 1) + 0.5}
            stroke={rgba(theme.foreground, backgroundGuideAlpha)}
            strokeWidth={1}
          />
        ))}

        <line
          x1={width - pointSize}
          y1={0}
          x2={width - pointSize}
          y2={height}
          stroke={rgba(theme.accent, 0.5)}
          strokeWidth={1}
        />
      </svg>

      <canvas
        ref={canvasRef}
        style={{
          maskImage: linearGradient,
          maskSize: 'cover',
        }}
      />
    </div>
  );
};
